<script>
import BaseVue from '@frontend/Base.vue';
import Gen from '../helper/Gen';

export default {
	extends: BaseVue,
	data() {
		return {
			row: false,
			terkait: false,
		}
	},
	async mounted() {
		await this.ready();
		this.getDetail()
	},
	methods: {
		getDetail(){
			this.loadingOverlay = true
			this.row = false
			Gen.apirest("/pertanyaan-detail/"+this.$route.params.slug, {}, (err, resp)=>{
				this.loadingOverlay = false
				if (resp.code == 404) return this.$router.push({name:'404'})
				this.row = resp.row
				this.getAnother()
			})
		},
		getAnother(){
			Gen.apirest("/pertanyaan-list", {tags:this.row.ad_tags,notid:this.row.ad_id,limit:4}, (err, resp)=>{
				this.terkait = resp.data
			})
		}
	},
	watch:{
		$route(){
			this.getDetail()
		}
	}
}
</script>
<template>
	<section id="content">
		<div class="content-wrap">
			<section class="section nobg nomargin">
				<div class="container">
					<a href="javascript:;" @click="$router.back()"><i class="icon-arrow-left align-bottom mr-2"></i> Kembali</a>
					<div class="row" v-if="row">
						<div class="col-md-8">
							<div class="question-wrap card">
								<div class="card-body">
									<div class="qw-title">
										<h3>{{row.ad_subject}}</h3>
										<span>Oleh : {{row.ad_fullname}}</span><span>{{row.ad_create_at.dates("format")}}</span>
										<span v-if="row.ad_status=='Y'" class="text-success answered"><i class="icon-check"></i> Pertanyaan Terjawab</span>
									</div>
									<div class="qw-content">
										<p>{{row.ad_question}}</p>
									</div>
								</div>
							</div>
							<div class="question-wrap card" v-if="row.ad_status=='Y'">
								<div class="card-body">
									<div class="qw-title">
										<h4 class="text-success"><i class="icon-check"></i> Dijawab oleh {{row.ad_answerd_by}}</h4>
									</div>
									<div class="qw-content" v-html="row.ad_answer"></div>
								</div>
							</div>
							<div class="question-wrap" v-if="terkait&&terkait.total">
								<div class="article-list">
									<h3>Pertanyaan Terkait</h3>
                                    <div class="article-item" v-for="(v,k) in terkait.data" :key="k">
                                        <router-link :to="{name:'DetailPertanyaan',params:{slug:v.ad_slug}}">
                                            <div class="article-desc">
                                                <div class="d-flex mb-2">
                                                    <div class="succ-content d-none d-md-flex" :class="{'not-answered':v.ad_status!='Y'}">
                                                        <div class="sc-caption">
                                                            <i class="icon-check"></i>
                                                        </div>
                                                    </div>
                                                    <div class="ask-heading">
                                                        <h4>{{v.ad_subject}}</h4>
                                                        <span>Oleh : {{v.ad_fullname}}</span>
                                                        <span class="text-success answered" v-if="v.ad_status=='Y'"><i class="icon-check d-inline d-md-none"></i> Pertanyaan Terjawab</span>
                                                    </div>
                                                </div>
                                                <p>{{v.ad_question}}</p>
                                            </div>
                                        </router-link>
                                    </div>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="question-wrap card">
								<div class="card-body center">
									<h4>Tidak puas dengan jawaban ini?</h4>
									<p>Anda bisa mengajukan pertanyaan langsung kepada kami. Dokter terpercaya
										kami siap menjawab pertanyaan Anda seputar kesehatan.</p>
									<router-link :to="{name:'TanyaDoctor',query:{applyForm:1}}" class="primary-btn btn-block">Tanyakan Sekarang</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>

	</section>
</template>